<template>
    <div class="row" v-if="reviews.length">
      <div class="col xl2 l3 s6 m3" v-for="(review, reviewIndex) in reviews" :key="reviewIndex">
          <a 
            :href="review.media_type === 'document' ? review.sizes[7].url: review.sizes[0].url" 
            class="award"
            data-fancybox="gallery"
        ><img :src="review.preview" /></a>
      </div>
      <p class="col s12">
        <a class="btn" target="_blank" href="https://t.me/hpace_reviews">Больше отзывов</a>
      </p>
    </div>
    <ThePreloader v-else />
</template>

<script>
    export default {
        name: 'TheAwards',
        data () {
            return {
                reviews: []
            }
        },
        async mounted () {
            let reviews = await fetch('https://hpace.ru/ydisk.php?typeData=reviews').then(r => r.json())
            this.reviews = reviews._embedded.items
        }
    }
</script>