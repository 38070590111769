import Vue from 'vue'
import App from './App.vue'
import ThePreloader from './components/ThePreloader'

Vue.prototype.$ = window.$;

Vue.component('ThePreloader', ThePreloader)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
