<template>
    <span :class="(!init ? 'loading' : (hasOnline ? 'online' : 'offline'))"></span>
</template>

<script>
    export default {
        name: 'TheCheckServer',
        
        data () {
            return {
                hasOnline: false,
                init: false,
            }
        },  

        props: ['link'],

        async mounted () {
            await fetch(this.link, {
                mode: 'no-cors'
            }).then(() => {
                this.hasOnline = true 
            }).catch(() => {
                this.hasOnline = false
            })

            this.init = true 
        }
    }
</script>

<style scoped>
    
</style>