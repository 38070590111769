<template>
  <div id="app" class="container" ref="container">
    <img width="100%" class="header-img" src="./assets/header.png" />

    <div class="row Header Hpace-bg">
      <div class="col m6">
        <div class="row m0">
          <div class="col">
            <div class="logo-container">
              <img width="100%" class="logo" src="./assets/logo.png" />
            </div>
          </div>
          <div class="col left-align">
            <h5 class="online">
              <a href="https://andrey-tishenko.ru" target="_blank">Андрей Тищенко</a>
            </h5>
            <p>Опыт работы: <b>7 лет, команде 5 лет.</b></p>
            <p><i class="material-icons">done_all</i> <a href="https://www.rusprofile.ru/ip/319703100078031" target="_blank">Официально подтвержденная компания</a></p>
            <p><b>от 1800 ₽ / час.</b> <span class="badge new" data-badge-caption="">Свободен для работы</span></p>
          </div>
        </div>
      </div>
      <div class="col m3 left-align">
        <h5><i class="material-icons">contact_phone</i> Контакты</h5>

        <p><a href="tel:+79293744874"><i class="material-icons">perm_phone_msg</i> +7 929 374 48 74</a></p>
        <p><a href="https://teleg.run/mazaretto"><img src="./assets/social/tg.svg" width="12" /> @mazaretto</a></p>

        <a href="https://github.com/mazaretto" target="_blank"><img src="./assets/social/gh.svg" width="25" /></a>
        <a href="" target="_blank"><img src="./assets/social/in.svg" width="25" /></a>
        <a href="https://vk.com/webstage" target="_blank"><img src="./assets/social/vk.svg" width="25" /></a>
        <a href="https://instagram.com/andrew.tischenko" target="_blank"><img src="./assets/social/inst.svg" width="25" /></a>
        <a href="https://teleg.run/andrey_tischenko" target="_blank"><img src="./assets/social/tg.svg" width="25" /></a>
        <a href="https://wa.me/+79293744874" target="_blank"><img src="./assets/social/wa.svg" width="25" /></a>
        <a href="https://www.behance.net/hpace" target="_blank"><img src="./assets/social/beh.svg" width="25" /></a>

        <p>
          <a href="https://workspace.ru/contractors/hpace-ru/" target="_blank"> <img src="https://workspace.ru/local/tools/verification.php?code=f0616898ca6a67ed4f82a70877092ec9&amp;type=ver7" alt="Мы на Workspace" width="100"> </a>
        </p>

      </div>
      <div class="col m3 left-align">
        <h5><i class="material-icons">equalizer</i> Рейтинги</h5>
        <p><i class="material-icons">timeline</i> <b>170+ занятий</b> как <a href="https://solvery.io/ru/mentor/hpace" target="_blank">ментор</a></p>
        <p><i class="material-icons">star</i> <b>4 продукта</b> которые поддерживаем</p>
        <p><i class="material-icons">people</i> <b>15 специалистов</b> в команде</p>
        <p><i class="material-icons">thumb_up</i> <b>80+ отзывов</b></p>
      </div>
    </div>

    <div class="row">
      <div class="col left-align">
        <a href="#" @click.prevent="activeTab=tab" v-for="(tabIndex, tab) in tabs" :key="tab" :class="'Hpace-tab btn ' + (activeTab === tab ? '' : '#3949ab indigo darken-1')">{{ tab }}</a>
      </div>
    </div>

    
    
    <div class="row" v-if="activeTab === 'Презентация'">
      <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vR1RMmAi2gkHC7SMRWNnD1UO_lNMSm0ujPEadY5RBe6ixZemDJVY9erJ4jSp1Jcng/embed?start=true&loop=false&delayms=10000" frameborder="0" width="100%" height="550" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
      <p>
        <a class="btn" target="_blank" href="https://hpace.ru/prez.pdf">Посмотреть полную презентацию</a>
      </p>
    </div>

    <TheAwards v-if="activeTab === 'Благодарности, награды'" />
    <ThePortfolio v-if="activeTab === 'Портфолио'" />
    <TheYoutube v-if="activeTab === 'Выступления, отзывы'" />
    <TheReviews v-if="activeTab === 'Ещё отзывы'" />

  </div>
</template>

<script>
import { Fancybox } from "@fancyapps/ui"
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import ThePortfolio from './components/ThePortfolio'
import TheYoutube from './components/TheYoutube'
import TheReviews from './components/TheReviews'
import TheAwards from './components/TheAwards'

export default {
  name: 'App',
  data () {
    return {
      activeTab: 'Портфолио',
      tabs: {
        'Портфолио': true,
        'Благодарности, награды': false,
        'Выступления, отзывы': false,
        'Презентация': false,
        'Ещё отзывы': false
      },
      reviews: []
    }
  },
  components: {
    ThePortfolio, TheYoutube, TheReviews, TheAwards
  },

  

  async mounted () {
    Fancybox.bind(this.$refs.container, '[data-fancybox]', {
      ...(this.options || {}),
    });
  }
}
</script>

<style>
.material-icons {
  font-size: 15px;
}
.logo-container {
  width: 100px;
  border: 2px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  height: 100px;
}

.online, .offline, .loading {
  position: relative;
  padding-left: 20px;
}

.offline:before {
  background: #f53939!important;
}

.online:before {
  background: #04cd5e!important;
}

.loading:before {
  background: #333!important;
}

.online:before,
.offline:before,
.loading:before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  top: 8px;
  position: absolute;
  transition: 1s ease;

  left: 0;
}

.Hpace-tab {
  margin: 5px 5px 5px 0px;
}

.Hpace-bg {
  background: rgba(10,19,51,.75)!important;
}

.Header {
  box-shadow: 0px 0px 100px #000;
  color: #fff;
  padding: 15px;
}

.header-img,
.btn, .Header {
  border-radius: 25px;
}

.HpaceCopyright {
  color: white;
}

.lightrope {
  top: 0!important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top: 25px;
}

body {
  min-height: 100vh;
  background-color: #000;
}

body:before {
  content: '';
  overflow-x: hidden;
  background: url('./assets/bg.png') no-repeat center center / cover;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}
</style>
