<template>
    <div v-if="initedCases">
        <div class="row PortfolioItem" v-for="(caseCategory, caseCategoryIndex) in cases" :key="caseCategoryIndex">
            <div class="col s12">
                <h5 class="white-text cursor-pointer" @click.prevent="activeCase === caseCategoryIndex ? activeCase = '' : activeCase=caseCategoryIndex"><span>{{ caseCategoryIndex === activeCase ? '—' : '+' }}</span> {{ caseCategoryIndex }} <small>({{ caseCategory.length }} шт.)</small></h5>
            </div>
            <div v-for="(caseItem, caseItemIndex) in caseCategory" :key="caseItemIndex" class="col m4">
                <div v-if="caseCategoryIndex === activeCase" class="card Hpace-card Hpace-bg">
                    <a :href="`https://hpace.ru/assets/images/screens/${caseItem.id}_full.png`" data-fancybox class="card-image card-image-case">
                        <img :src="`https://hpace.ru/assets/images/screens/${caseItem.id}.png`" v-on:error="regenerateImgCase">
                    </a>
                    <div class="card-content white-text">
                        <span class="card-title card-title-case">
                            <a 
                                :href="getCaseLink(caseItem)"
                                target="_blank"
                            >
                                <TheCheckServer :link="getCaseLink(caseItem)" /> {{ caseItem.title ? caseItem.title : caseItem.name }}
                            </a> 
                            <span class="badge">Сложность {{ caseItem.lvl }} из 5</span>
                        </span>
                        <p>от {{ caseItem.budget }} руб. / <b>#{{ caseItem.sphere }}</b></p>
                    </div>
                    <div class="card-action">
                        <a class="modal-trigger" :href="`#modalMore${caseItem.id}`">Подробнее</a>
                        <a class="modal-trigger" :href="`#modal${caseItem.id}`" v-if="caseItem.demo_login">Демо-доступ</a>
                    </div>

                    <div :id="`modalMore${caseItem.id}`" class="modal">
                        <div class="modal-content">
                            <h4>Проект "{{ caseItem.title }}"</h4>
                            <table class="striped">
                                <tbody>
                                    <tr>
                                        <td>
                                            <b>Наименование</b><br/>
                                            {{ caseItem.name }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Название</b><br/>
                                            {{ caseItem.title }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Описание</b><br/>
                                            <pre>{{ caseItem.description }}</pre>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Заказчик</b><br/>
                                            <p>{{ caseItem.client }}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Срок</b><br/>
                                            <p>{{ caseItem.deadline }}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <a href="#!" class="modal-close waves-effect waves-green btn-flat">Закрыть</a>
                        </div>
                    </div>

                    <div :id="`modal${caseItem.id}`" v-if="caseItem.demo_login" class="modal">
                        <div class="modal-content">
                            <h4>Демо-доступы к проекту "{{ caseItem.title }}"</h4>
                            <p>
                                <a :href="getCaseLink(caseItem)" target="_blank">Ссылка</a><br/>
                                Логин/E-mail: {{ caseItem.demo_login }}<br/>
                                Пароль: {{ caseItem.demo_password }}
                            </p>
                        </div>
                        <div class="modal-footer">
                            <a href="#!" class="modal-close waves-effect waves-green btn-flat">Закрыть</a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <ThePreloader v-else />
</template>

<script>
import TheCheckServer from './helpers/TheCheckServer'
import defaultCaseImg from '../assets/case.png'

export default {
    name: 'ThePortfolio',
    
    components: { TheCheckServer },

    data () {
        return {
            initedCases: false,
            activeCase: 'CRM',
            cases: {
                'CRM': [],
                'Интернет-магазин': [],
                'Landing-Page': [],
                'Веб-приложение': [],
                'Корпоративный сайт': [],
                'Поддержка': [],
                'Портал': [],
                'Скрипт': [],
                'Telegram-бот': [],
                'Новостной сайт': []
            }
        }
    },

    methods: {
        getCaseLink ({ link, link_demo, link_design }) {
            let $link = link.indexOf('.') > -1 ? link : ( link_demo ? link_demo : link_design )
 
            if(!($link.indexOf('://') > -1)) {
                $link = 'https://' + $link;
            }

            return $link
        },

        regenerateImgCase (item) {
            item.target.src = defaultCaseImg
        }
    },  

    updated () {
        this.$('.modal').modal()
    },

    async mounted () {
        let data = await fetch('https://crm.hpace.ru/api/portfolio').then(r => r.json())

        if(data) {
            data.forEach(item => {
                this.cases[item.type_project] = [...this.cases[item.type_project], {...item}]
            })

            for(let i in this.cases) {
                if(!this.cases[i].length)
                    delete this.cases[i]
            }
            
            this.initedCases = true
        }
    }
}
</script>

<style>
    .card-title-case {
        font-size: 20px;
    }
    .card-image-case {
        display:block;
        height: 150px;
        overflow: hidden;
    }
    .PortfolioItem {
        text-align: left;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .Hpace-card {
        border-radius: 25px;
        overflow: hidden;
    }
</style>