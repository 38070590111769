<template>
    <div class="row" v-if="reviews.length">
      <div class="col xl2 l3 s6 m4" v-for="(review, reviewIndex) in reviews" :key="reviewIndex">
          <a :href="review.sizes[0].url" data-fancybox="gallery_reviews"><img :src="review.preview" /></a>
      </div>
      <p class="col s12">
        <a class="btn" target="_blank" href="https://t.me/hpace_reviews">Больше отзывов</a>
      </p>
    </div>
    <ThePreloader v-else />
</template>

<script>
    export default {
        name: 'TheReviews',
        data () {
            return {
                reviews: []
            }
        },
        async mounted () {
            let reviews = await fetch('https://hpace.ru/ydisk.php?typeData=reviews_screen').then(r => r.json())
            this.reviews = reviews._embedded.items
        }
    }
</script>