<template>
    <div class="row" v-if="items.length">
        <a 
            data-fancybox 
            class="col s6 m4 Youtube_item" 
            v-for="(item, itemIndex) in items" :href="`https://www.youtube.com/watch?v=${item.snippet.resourceId.videoId}`" 
            :key="itemIndex"
        >
            <img class="Youtube_item__image" src="../assets/social/yt.png" />
            <img width="100%" :src="item.snippet.thumbnails.medium.url" />
        </a>
    </div>
    <ThePreloader v-else />
</template>

<script>

    export default {
        name: 'TheYoutube',
        data () {
            return {
                items: [],
                reviews: []
            }
        },
        async mounted () {
            let data = await fetch('https://hpace.ru/ydisk.php?typeData=yt_events').then(r => r.json())
            let dataReviews = await fetch('https://hpace.ru/ydisk.php?typeData=yt_reviews').then(r => r.json())

            this.items = [...data.items, ...dataReviews.items]
        }
    }

</script>

<style scoped>

    .Youtube_item {
        position: relative;
        transition: .5s ease;
    }
    .Youtube_item:hover {
        opacity: .7;
        transform: scale(1.02);
    }
    .Youtube_item__image {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

</style>